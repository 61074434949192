import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function LoginForm({ setIsAuthenticated }) {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(""); // Adicionando estado para armazenar erro
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://api-foodflw-production.up.railway.app/login",
        {
          email,
          senha,
        }
      );

      // Extrai o token JWT da resposta do servidor
      const token = response.data.token;

      // Armazena o token JWT no localStorage
      localStorage.setItem("token", token);

      // Define o estado de autenticação como verdadeiro
      setIsAuthenticated(true);

      // Redireciona para a página inicial
      navigate("/vendas");
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      // Define o erro de acordo com a resposta do servidor
      setError("Email e/ou senha incorreto(s)");
    }
  };

  return (
    <div className="flex h-screen overflow-hidden lg:flex-row">
      <div className="hidden lg:flex lg:w-2/3"></div>
      <div className="mt-14 ml-9 flex h-full flex-col items-start sm:mt-6 sm:ml-6 sm:w-full md:w-1/2 lg:mt-0 lg:w-1/3 lg:items-start lg:justify-center lg:px-6">
        <div className="flex w-full flex-col items-start justify-center space-y-6 py-6 lg:mb-20 lg:h-full lg:items-start lg:py-0 lg:pt-0">
          <h1 className="font-orelega mb-9 text-3xl font-bold text-green-800 lg:mt-0 lg:mb-6 lg:self-center">
            <span className="mb-9 ml-20 text-3xl font-bold text-green-800 lg:ml-0 lg:hidden"></span>
          </h1>
          <p className="mb-5 text-5xl font-bold text-darkgreen">
            Bem <br /> vindo!
          </p>
          {error && ( // Mostra o aviso de erro apenas se houver um erro
            <p className="text-vermelho font-bold ">{error}</p>
          )}
          <div className="mb-4 w-80">
            <label className="mb-2 block text-darkgreen text-xl">Email</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full rounded-lg border border-darkgreen bg-gray-100 p-3 outline-none  focus:ring-0"
            />
          </div>
          <div className="relative mb-4 w-80">
            <label className="mb-2 block text-darkgreen text-xl ">Senha</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="**********"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              className="block w-full rounded-lg border border-darkgreen bg-gray-100 p-3 outline-none  focus:ring-0"
            />
            <span
              onClick={() => setShowPassword((prev) => !prev)}
              className="absolute right-4 top-12 cursor-pointer"
            >
              {showPassword ? "👨‍🍳" : "👁️"}
            </span>
          </div>
          <div className="mb-4 flex w-80 justify-between">
            <button
              onClick={handleLogin}
              className="mt-5 w-36 rounded-lg text-lg bg-darkgreen p-2 text-white hover:bg-ultradarkgreen"
            >
              Login
            </button>
            <Link to="/register">
              <button className="mt-5 w-36 rounded-lg text-lg bg-darkgreen p-2 text-white hover:bg-ultradarkgreen">
                Cadastre-se
              </button>
            </Link>
          </div>
          <div className="mb-4 flex w-80 justify-between space-x-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="mr-2 accent-darkgreen"
              />
              <label className="mb-1 text-md   text-gray-800">
                Lembrar de mim
              </label>
            </div>
            <Link className="text-md px-2" to="/redefinir-senha">
              Esqueceu a senha?
            </Link>
          </div>
          <div className="ml-1 mt-12 mb-4 flex w-80 flex-col space-y-4"></div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
