import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import VendaPratos from "./components/VendaPratos";
import Home from "./components/Home";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<Home setIsAuthenticated={setIsAuthenticated} />}
          />

          <Route
            path="/vendas"
            element={isAuthenticated ? <VendaPratos /> : <Navigate to="/" />}
          />
          {/* Outras rotas podem ser adicionadas aqui */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
